a {
  font-size: 2vh;
  padding-right: 10px;
  transition: 0.1s;

  &:link {
    color: inherit;
  }

  &:hover, &:visited:hover {
    color: inherit;
    opacity: 0.5;
  }

  &:visited  {
    color: inherit;
  }
}