@import "../../variables";

.contact {
  background: $accentColor;
  width: 100%;
  color: white;
  overflow: hidden;

  .contentWrapper {
    margin: auto auto;
    flex-flow: row wrap;
    align-content: stretch;
    justify-content: space-around;
    width: 100%;
    max-width: 2000px;
    padding-bottom: 100px;

    h1, h3, p, label {
      text-align: center;
      display: block;
    }

    .imprint {
      margin-top: 100px;
      transition: opacity 0.2s;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }

    label {
      margin: 0 auto;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bolder;
      color: rgba(255, 255, 255, 0.5);

      a:link, a:hover, a:visited {
        margin: inherit;
        text-transform: inherit;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        text-decoration: none;
      }
    }

    p, a {
      font-size: 1rem;
    }

  }
}