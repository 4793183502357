@import "../../variables";

.about {
  width: 100%;
  background: $accentColor;
  overflow: hidden;
  padding: 0 10vw 10vw;
  color: white;
  box-sizing: border-box;

  h1 {
    font-weight: bolder;
    text-align: center;
    padding-bottom: 3rem;
  }

  .contentWrapper {
    color: white;
    display: flex;
    flex-flow: row wrap;
    align-content: stretch;
    justify-content: space-around;
    width: 100%;
    max-width: 2000px;
    margin: auto auto;
    padding-bottom: 2rem;

    .left, .right {
      flex-grow: 1;
      flex-shrink: 1;
      width: 50vh;
    }

    &.aboutCards {
      box-sizing: border-box;
      padding-top: 12rem;

      .card {
        color: $accentColor;
        flex-shrink: 1;
        flex-grow: 1;
        width: 300px;
        background: white;
        margin: 20px;
        padding: 2rem;
        text-align: center;
        border-radius: 5px;
        opacity: 0.8;
        transition: transform 0.2s, opacity 0.2s;

        svg {
          display: block;
          margin: 1rem auto 2rem;
          font-size: 3rem;
        }

        &:hover {
          opacity: 1;
          transform: translateY(-5px) scale(1.01);
        }

        label {
          text-transform: uppercase;
          font-size: 0.8rem;
          font-weight: bolder;
          color: rgba(0,0,0,0.5);
        }
      }
    }

    .left {
      //width: 50vw;
      margin: auto auto;
      background: rgba(255,255,255,0.00);
      color: rgba(255,255,255,0.01);
      box-sizing: border-box;
      //box-shadow: inset -10px 10px 50px rgba(0,0,0,0.5);

      .image {
        box-shadow: 0 0 20px rgba(0,0,0,0.2);
        z-index: 4;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 5px;
        transition: transform 0.1s;
      }

    }

    .right .wrapper {
      //width: 25vw;
      //min-width: 12.5vh;
      padding: 10vw;
      text-align: justify;
      margin: auto auto;
      color: $accentColor;
      background: white;
      z-index: 1;


      label {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: bolder;
        opacity: 0.5;
      }

      a {
        background: transparent;
      }

      .contact {
        font-size: inherit;
      }
    }
  }
}