.imprintComponent {
  .overlay {
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    overflow: hidden;
  }

  .imprintWrapper {

    width: 90vw;
    height: 90vh;
    margin-top: 5vh;
    position: relative;
    margin-left: 5vw;
    box-sizing: border-box;
    background: white;
    overflow-y: auto;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    .closeButton {
      position: absolute;
      font-size: 1.2rem;
      top: 5%;
      right: 5%;
      color: black;
      transition: color 0.2s;

      &:hover {
        color: rgba(0,0,0,0.5);
        cursor: pointer;
      }
    }

    .contentWrapper {
      padding: 5vw;
      position: relative;
      color: black;
      max-width: 500px;
      margin: auto auto;
      text-align: left;

      h1, h2, a, li, p {
        text-align: left;
      }
    }
  }
}