@import "../../variables";

.intro {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("https://danilowalenta.com/seoul.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  //background-attachment: fixed;
  //text-shadow: 1px 0 black, 0px 1px black, -1px 0 black, 0px -1px black; //, 1px 1px black, -1px -1px black
  text-align: center;
  transform-origin: center center;
  perspective: 2500px;

  .effectWrapper {
    .effect {
      position: absolute;
      color: $accentColor;
      transform-origin: inherit;
      //font-size: 23vw;
      font-size: 11.5vw;
      font-weight: bolder;
      // color: rgba(0,0,0,0.2);
      opacity: 0.9;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &::after {
    background: $accentColor;
    content: "";
    width: 150%;
    height: 100vh;
    top: 80vh;
    transform-origin: left top;
    transform: rotate(5deg);
    left: 0;
    position: absolute;
  }

  .downArrow {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    color: white;
    transition: transform 0.2s, opacity 0.2s;
    margin: 0 auto;
    z-index: 100;
    font-size: 2rem;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      transform: translateY(-5px) translateX(-50%);
      opacity: 0.7;
    }
  }
}

/**
Navigation
 */

.navigation {
  background: transparentize(white, 0);
  z-index: 5;
  position: absolute;
  overflow: hidden;
  text-align: left;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  //box-shadow: 0 0 20px rgba(0,0,0,0.2);
  color: $accentColor;
  padding: 2rem;
  opacity: 0.9;
  border-radius: 5px;
  //border-top: 1px $accentColor solid;
  //text-shadow: 1px 0 white, 0px 1px white, -1px 0 white, 0px -1px white;

  a {
    align-self: center;
    text-align: center;
    margin: 0 auto;
    flex-grow: 1;
    flex-shrink: 1;
    font-weight: bolder;
    //lighter;
    //color: white;
    font-size: 1rem;
    transition: 0.2s;
    padding: 0 1.5rem;
    text-transform: uppercase;

    &:hover {
      opacity: 0.75;
      transform: translateY(-2px);
      padding: 0 1.5rem;
      cursor: pointer;
    }
  }

  div {
    font-size: 1rem;
    text-align: center;
    margin: 2rem auto 0;
    flex-grow: 3;
  }
}