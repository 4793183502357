@import "../../variables";

.projects {
  width: 100%;
  overflow: hidden;
  //background: transparent;// #c8c8c8;
  background-image: url("https://danilowalenta.com/botgar.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .projectDiv{
    background: linear-gradient( to right, transparent,
            rgba($accentColor, 0.8), rgba($accentColor, 0.8),
            //$accentColor 40%,
            //$accentColor 60%,
            transparent 100%);
    transform: rotate(5deg);
    margin-top: 17vh;


  .contentWrapper {
    margin: 10% auto auto;
    flex-flow: row wrap;
    align-content: stretch;
    justify-content: space-around;
    width: 100%;
    max-width: 6000px;
    overflow: hidden;
    transform: rotate(-5deg);


    h1 {
      color: white;//$accentColor;
      padding: 5vw 10vw;
      text-align: center;
    }

    .projectsWindow {
      color: black;
      $projectSize: 500px;
      width: $projectSize;
      max-width: 90vw;
      height: $projectSize;
      margin: 1rem auto;

      .projectsWrapper {
        display: flex;
        transition: transform 0.5s;
        position: absolute;
        perspective: 5000px;

        .project {
          height: $projectSize;
          width: $projectSize;
          max-width: 90vw;
          background: white;
          color: black;
          box-sizing: border-box;
          margin: 0 5vw 0 0 ;
          flex-shrink: 0;
          border-radius: $borderradius;
          position: relative;
          overflow: hidden;
          transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;

          .backgroundImg {
            opacity: 0.7;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            transition: opacity 0.5s, transform 0.5s;
          }

          &.active {
            opacity: 1;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
          }

          h2 {
            margin: 0;
            padding: 1rem 0 0;
            transition: 0.4s;
            transition-delay: 0.1s;
          }

          h5 {
            font-size: 0.7em;
            font-weight: lighter;
            color: gray;
            margin: 0;
            padding: 0 0 2rem;
            transition: 0.5s;
            transition-delay: 0.15s;
          }


          .titleSheet {
            padding: 3rem;
            box-sizing: border-box;
            position: absolute;
            background: rgba(255,255,255, 0.6);
            top: auto;
            bottom: 0;
            margin-top: auto;
            width: 100%;
            max-width: 100vw;
            overflow: hidden;
            backdrop-filter: blur(8px);
            margin-bottom: 0;
            transition: opacity 0.7s ease-out;

            .date {
              transition: 0.5s;

            }

            .buttons {
              overflow: hidden;
              position: relative;
              display: flex;
              transition: 0.5s;
              transition-delay: 0.2s;

              .button {
                font-size: 0.6em;
                color: white;
                background: $accentColor;
                border-radius: $borderradius;
                padding: 0.5rem 1rem;
                display: inline-block;
                margin-right: 1rem;
                transition: opacity 0.2s;

                &:hover {
                  opacity: 0.8;
                  cursor: pointer;
                }
              }

              a {
                position: relative;
                font-size: 1.0em;
                box-sizing: content-box;
                padding: .3rem;
                margin-right: 1rem;
              }
            }
          }

          .closeButton {
            padding: 1.5rem;
            position: absolute;
            right: 0;
            z-index: 1;
            transition: 1s ease-out;
          }

          .article {
            overflow-y: auto;
            transition: 0.5s;
            height: inherit;

            .articleContent {
              padding: 3rem;
              box-sizing: border-box;
              text-align: justify;

              label {
                text-transform: uppercase;
                font-size: 0.8rem;
                font-weight: bolder;
                color: rgba(0,0,0, 0.5);
                display: block;
              }

              a {
                font-size: 1.2rem;
                color: rgba(0,0,0, 0.5);
                transition: color 0.2s;
                padding: 10px 10px 10px 0;
                display: inline-block;

                &:hover {
                  color: rgba(0,0,0, 1);
                }
              }
            }
          }

          h2 {
            color: black;
          }

          &.closed {
            .closeButton {
              transform: translateY(-150%);
            }

            .article {
              transform: translateX(150%);
            }

            .titleSheet {
              h2, h5, .date, .buttons {
                transform: translateX(0);
              }
            }
          }

          &.open {
            .closeButton {
              transform: translateY(0);
            }

            .article {
              transform: translateX(0);
            }

            .titleSheet {
              opacity: 0;

              h2, h5, .date, .buttons {
                transform: translateX(-150%);
              }
            }

            .backgroundImg {
              transform: translateX(-150%);
              opacity: 0;
            }
          }

          &.inactive {
            opacity: 0.3;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            transform: scale(0.95) translateY(5%);
            user-select: none;

            .titleSheet .buttons {
              *:hover {
                opacity: 1 !important;
                cursor: auto;
              }
            }
          }
        }
      }
    }
  }}

  .projectNavigation {
    position: relative;
    display: block;
    padding-top: 3rem;
    color: white;//rgba(17, 25, 33, 0.5);
    text-align: center;
    width: auto;
    margin: 0 auto;
    margin-bottom: 3.5vh;



    .navigationWrapper {
      display: block;
      //background: transparentize(white, 0.1);
      //border-radius: 5px;


      div {
        display: inline-block;
        margin: 10px;
        transition: transform 0.2s, color 0.2s;

        &:hover {
          color: gray;//rgba(17, 25, 33, 1);
          transform: translateY(-10%);
          cursor: pointer;
        }
      }
    }

    .shuffle {
      align-content: center;
      display: block;
      margin: 0 auto;
      transition: transform 0.2s, color 0.2s;

      &:hover {
        color: grey;//rgba(17, 25, 33, 1);
        transform: translateY(-10%);
      }
    }

    .tooltip {
      font-size: 1.3em;
      font-weight: normal;
      color: white;//$accentColor;
      transition: 0.1s;

      &.invisible {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &::before {
    background: $accentColor;
    content: "";
    width: 150%;
    height: 40vh;
    display: block;
    margin-top: -40vh;
    transform-origin: left bottom;
    transform: rotate(5deg);
    //box-shadow: 0 0 20px rgba(0,0,0,0.8);
  }

  &::after {
    background: $accentColor;
    content: "";
    width: 150%;
    height: 15vh;
    display: block;
    margin-top: 10vh;
    transform-origin: left top;
    transform: rotate(5deg);
    //box-shadow: 0 0 20px rgba(0,0,0,0.8);
  }
}